import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import AdvocatePage from './pages/AdvocatePage';
import BlogPage from './pages/BlogPage';          // Nova rota para o blog
import BlogDetailPage from './pages/BlogDetailPage'; // Nova rota para os detalhes do blog
import ServicesPage from './pages/ServicesPage';
import ServiceDetailPage from './pages/ServiceDetailPage';
import SuccessCasePage from './pages/SuccessCasePage';

const App = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow pt-16">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/sobre" element={<AboutPage />} />
                        <Route path="/servicos" element={<ServicesPage />} />
                        <Route path="/services/:serviceId" element={<ServiceDetailPage />} />
                        <Route path="/cases-de-sucesso" element={<SuccessCasePage />} />
                        <Route path="/seja-advogado-dativo" element={<AdvocatePage />} />
                        <Route path="/blog" element={<BlogPage />} />              {/* Blog */}
                        <Route path="/blog/:postId" element={<BlogDetailPage />} /> {/* Detalhes do Blog */}
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
