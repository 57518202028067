import React, { useState } from 'react';

const specialties = [
    {
        title: 'Ciências Penais e Segurança Pública',
        description: 'Atuo em questões relacionadas ao direito penal e em processos administrativos de segurança pública. Busco sempre uma defesa técnica e detalhada, priorizando a melhor estratégia para cada situação.',
    },
    {
        title: 'Execuções Cíveis',
        description: 'Tenho experiência em resolver disputas de forma ágil e assertiva, seja na recuperação de créditos ou na defesa em execuções judiciais, sempre com foco na solução prática e eficiente.',
    },
];

const faq = [
    { question: 'Como agendar uma consulta com o advogado?', answer: 'Você pode entrar em contato pelo WhatsApp ou telefone disponíveis na página de contato para agendar sua consulta.' },
    { question: 'Quanto custa uma consulta jurídica?', answer: 'Os valores variam conforme o caso e a complexidade. Entre em contato para obter mais informações detalhadas.' },
    { question: 'O advogado atua em outras áreas além das especialidades mencionadas?', answer: 'Sim, dependendo do caso, analisamos a possibilidade de atuação ou indicamos um profissional de confiança.' },
    { question: 'Como funciona o processo de execução cível?', answer: 'Envolve a recuperação de créditos ou a execução de dívidas judiciais, sempre com foco em resolver rapidamente e de forma eficiente.' },
    { question: 'O que é considerado uma questão de segurança pública?', answer: 'Envolve casos relacionados à integridade física, crimes administrativos ou outras infrações que impactam a ordem pública.' },
    { question: 'Quanto tempo demora para resolver um caso?', answer: 'O tempo varia conforme a complexidade do processo e a carga do judiciário, mas sempre buscamos soluções rápidas e eficazes.' },
    { question: 'Quais documentos devo levar para uma consulta inicial?', answer: 'Documentos pessoais (RG, CPF), comprovante de residência e materiais relacionados ao caso, como contratos ou notificações.' },
];

const AboutPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="relative overflow-hidden">
            {/* Background Animation */}
            <div className="absolute inset-0 bg-gradient-to-r from-tertiary to-primary animate-gradient-x opacity-20"></div>

            <div className="relative z-10 py-12 px-6">
                <h1 className="text-4xl font-bold text-center text-primary mb-8 drop-shadow-lg">Sobre Mim</h1>
                <p className="text-lg text-justify text-black leading-relaxed mb-8">
                    Olá, seja bem-vindo! Sou Lucas Araújo, advogado especializado em Ciências Penais e Segurança Pública e em Execuções Cíveis. Ao longo da minha trajetória, construí uma carreira pautada pela ética, transparência e compromisso em buscar as melhores soluções jurídicas para cada cliente.
                </p>
                <p className="text-lg text-justify text-black leading-relaxed mb-12">
                    Acredito que cada caso é único e exige atenção personalizada. Meu objetivo é oferecer um atendimento próximo, estratégico e sempre focado em resultados, garantindo que os direitos de cada cliente sejam protegidos com excelência.
                </p>

                <h2 className="text-3xl font-bold text-center text-primary mb-8">Minhas Especialidades</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {specialties.map((specialty, index) => (
                        <div
                            key={index}
                            className="flex flex-col p-6 bg-tertiary text-secondary rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all hover:rotate-1"
                        >
                            <h3 className="text-xl font-semibold text-white mb-4">{specialty.title}</h3>
                            <p>{specialty.description}</p>
                        </div>
                    ))}
                </div>

                <h2 className="text-3xl font-bold text-center text-primary mt-16 mb-8">Por que Escolher Meus Serviços?</h2>
                <div className="flex flex-wrap gap-6 justify-center">
                    <div className="flex flex-col items-center p-8 bg-secondary text-primary rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 hover:rotate-1 transition-all">
                        <h3 className="text-2xl font-bold mb-4">Experiência</h3>
                        <p className="text-center text-black">
                            Meu trabalho é resultado de anos de dedicação ao estudo e à prática jurídica, sempre buscando me atualizar e aprimorar minhas técnicas.
                        </p>
                    </div>
                    <div className="flex flex-col items-center p-8 bg-secondary text-primary rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 hover:rotate-1 transition-all">
                        <h3 className="text-2xl font-bold mb-4">Atendimento Personalizado</h3>
                        <p className="text-center text-black">
                            Entendo que cada cliente possui necessidades específicas, e é meu dever criar soluções sob medida.
                        </p>
                    </div>
                    <div className="flex flex-col items-center p-8 bg-secondary text-primary rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 hover:rotate-1 transition-all">
                        <h3 className="text-2xl font-bold mb-4">Ética e Transparência</h3>
                        <p className="text-center text-black">
                            Confiança é essencial no relacionamento entre advogado e cliente. Priorizo a comunicação clara em todas as etapas do processo.
                        </p>
                    </div>
                </div>

                <h2 className="text-3xl font-bold text-center text-primary mt-16 mb-8">Perguntas Frequentes</h2>
                <div className="space-y-4">
                    {faq.map((item, index) => (
                        <div
                            key={index}
                            className="border-b border-gray-300 pb-4"
                        >
                            <button
                                onClick={() => toggleFAQ(index)}
                                className="w-full text-left flex justify-between items-center font-medium text-lg text-black hover:text-primary transition-all"
                            >
                                {item.question}
                                <span>{activeIndex === index ? '-' : '+'}</span>
                            </button>
                            {activeIndex === index && (
                                <p className="mt-2 text-black">
                                    {item.answer}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AboutPage;