import React, { useState } from 'react';

const AdvocatePage = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const benefits = [
        'Experiência prática em casos reais, com ampliação de habilidades jurídicas.',
        'Reconhecimento profissional por sua atuação no acesso à justiça.',
        'Possibilidade de remuneração fixa com base na tabela de honorários.',
        'Contribuição direta para a efetivação de direitos fundamentais.',
    ];

    return (
        <div className="relative overflow-hidden">
            {/* Background Animation */}
            <div className="absolute inset-0 bg-gradient-to-r from-tertiary to-primary animate-gradient-x opacity-20"></div>

            <div className="relative z-10 py-12 px-6">
                {/* Hero Section */}
                <section className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-primary mb-6 drop-shadow-lg">
                        Seja um Advogado Dativo
                    </h1>
                    <p className="text-lg text-black max-w-3xl mx-auto mb-6 leading-relaxed">
                        Descubra como contribuir para a justiça e garantir assistência jurídica a quem mais precisa.
                    </p>
                </section>

                {/* Legal Base Section */}
                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-primary mb-6 drop-shadow-md">A Base Legal da Advocacia Dativa</h2>
                    <p className="text-justify text-black leading-relaxed mb-6">
                        A advocacia dativa é regulamentada em âmbito estadual e se encontra respaldada no{' '}
                        <strong>artigo 5º, inciso LXXIV da Constituição Federal de 1988</strong>, que assegura: "O Estado
                        prestará assistência jurídica integral e gratuita aos que comprovarem insuficiência de recursos."
                        Esta garantia constitucional é operacionalizada através de convênios firmados entre a Ordem dos
                        Advogados do Brasil (OAB) e os tribunais de justiça, em conformidade com legislações estaduais
                        específicas.
                    </p>
                    <p className="text-justify text-black leading-relaxed mb-6">
                        Em muitos estados, a advocacia dativa é regida por legislações estaduais específicas que
                        determinam os critérios para o cadastro, nomeação e remuneração dos advogados dativos. Por
                        exemplo, no estado de São Paulo, o convênio entre a OAB-SP e a Defensoria Pública é regulamentado
                        pela <strong>Lei Complementar nº 988/2006</strong>. Já em Minas Gerais, a{' '}
                        <strong>Lei Estadual nº 21.720/2015</strong> detalha as atribuições e prerrogativas dos advogados
                        dativos no estado.
                    </p>
                </section>

                {/* Benefits Section */}
                <section className="mb-12">
                    <h3 className="text-3xl font-bold text-primary mb-6 drop-shadow-md">Benefícios para Advogados Dativos</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                        {benefits.map((benefit, index) => (
                            <div
                                key={index}
                                className={`p-6 bg-secondary text-primary rounded-lg shadow-md transform transition-transform duration-300 ${hoveredIndex === index
                                    ? 'scale-105 shadow-lg rotate-1'
                                    : 'hover:scale-105 hover:shadow-lg hover:rotate-1'
                                    }`}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                <p className="text-lg font-semibold leading-relaxed">{benefit}</p>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <section className="text-center">
                    <a
                        href="https://api.whatsapp.com/send?phone=5551999999999&text=Olá, gostaria de me cadastrar como Advogado Dativo."
                        className="bg-primary text-secondary px-6 py-3 rounded-lg shadow-md hover:shadow-lg hover:bg-tertiary hover:text-primary transition-all"
                    >
                        Cadastre-se como Advogado Dativo
                    </a>
                </section>
            </div>
        </div>
    );
};

export default AdvocatePage;