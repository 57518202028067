import React from 'react';
import { useParams, Link } from 'react-router-dom';

const blogPosts = [
    {
        id: 1,
        title: "Artigo 1: Introdução à Advocacia",
        date: "2024-01-01",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        id: 2,
        title: "Artigo 2: Direito Imobiliário",
        date: "2024-01-02",
        content: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        id: 3,
        title: "Entenda a Diferença Entre Contrato de Compra e Venda e Contrato de Locação",
        date: "2024-01-10",
        content: `
<div className="introduction-section py-12 px-6 bg-white">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center max-w-5xl mx-auto">
        <div>
            <p className="text-lg leading-relaxed text-gray-800">
                Você sabe a diferença entre um contrato de compra e venda e um contrato de locação? Embora ambos sejam
                instrumentos essenciais nas relações jurídicas imobiliárias, suas finalidades, direitos e deveres envolvidos
                são completamente distintos. Entender essas diferenças pode evitar conflitos e garantir que todas as partes
                envolvidas estejam protegidas.
            </p>
        </div>
        <div className="flex justify-center">
            <img src="/images/contract-difference.jpg" alt="Ilustração sobre contratos" className="rounded-lg shadow-md max-w-full" />
        </div>
    </div>
</div>

<div className="section py-12 bg-gray-100">
    <div className="max-w-5xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-primary mb-6">O Contrato de Compra e Venda</h2>
        <p className="mb-4 text-gray-700">
            O contrato de compra e venda é um instrumento jurídico que formaliza a transferência da propriedade de um bem entre
            o vendedor e o comprador mediante pagamento de um preço determinado. É amplamente utilizado na comercialização de
            imóveis.
        </p>
        <ul className="list-disc list-inside text-gray-800">
            <li><strong>Natureza definitiva:</strong> Tem como objetivo a transferência de propriedade.</li>
            <li><strong>Obrigatoriedade de escritura pública:</strong> Para bens imóveis de valor superior a 30 salários mínimos, é obrigatória a escritura pública para validade.</li>
            <li><strong>Pagamento:</strong> Pode ser à vista ou parcelado, com possibilidade de cláusulas de financiamento.</li>
        </ul>
    </div>
</div>

<div className="section py-12 px-6">
    <div className="max-w-5xl mx-auto">
        <h3 className="text-2xl font-bold text-primary mb-6">Direitos e Deveres</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="p-6 bg-white rounded-lg shadow-md">
                <h4 className="font-semibold text-gray-800 mb-4">Do Comprador</h4>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Exigir a transferência do imóvel livre de ônus.</li>
                    <li>Pagar o valor acordado nos prazos estabelecidos.</li>
                </ul>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <h4 className="font-semibold text-gray-800 mb-4">Do Vendedor</h4>
                <ul className="list-disc list-inside text-gray-700">
                    <li>Receber o preço combinado.</li>
                    <li>Garantir que o imóvel esteja livre de débitos e pendências legais.</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div className="section py-12 bg-gray-100">
    <div className="max-w-5xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-primary mb-6">Principais Diferenças Entre Compra e Venda e Locação</h2>
        <div className="overflow-x-auto">
            <table className="table-auto w-full text-left border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border border-gray-300 px-4 py-2">Aspecto</th>
                        <th className="border border-gray-300 px-4 py-2">Compra e Venda</th>
                        <th className="border border-gray-300 px-4 py-2">Locação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-gray-300 px-4 py-2">Propriedade</td>
                        <td className="border border-gray-300 px-4 py-2">Transfere a propriedade ao comprador.</td>
                        <td className="border border-gray-300 px-4 py-2">Mantém a propriedade com o locador.</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 px-4 py-2">Prazo</td>
                        <td className="border border-gray-300 px-4 py-2">Geralmente definitivo.</td>
                        <td className="border border-gray-300 px-4 py-2">Temporário, conforme o contrato.</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 px-4 py-2">Pagamento</td>
                        <td className="border border-gray-300 px-4 py-2">Preço definido no contrato.</td>
                        <td className="border border-gray-300 px-4 py-2">Aluguel mensal ou periódico.</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 px-4 py-2">Legislação</td>
                        <td className="border border-gray-300 px-4 py-2">Código Civil.</td>
                        <td className="border border-gray-300 px-4 py-2">Lei do Inquilinato.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div className="conclusion-section py-16 px-6 text-center bg-white">
    <div className="max-w-3xl mx-auto">
        <p className="text-lg text-gray-800 mb-6">
            Tanto o contrato de compra e venda quanto o contrato de locação desempenham papéis fundamentais no mercado
            imobiliário, mas atendem a finalidades distintas. Antes de firmar qualquer contrato, consulte um advogado especializado.
        </p>
        <a href="/contact" className="bg-primary text-white px-8 py-4 rounded-full shadow-md hover:bg-tertiary hover:text-primary transition-all">
            AGENDAR CONSULTORIA
        </a>
    </div>
</div>

        `,
    },
    {
        id: 4,
        title: "Artigo 4: O Que Considerar Antes de Comprar um Imóvel: Guia Jurídico Completo",
        date: "2024-01-02",
        content: `
        <div className="section py-16 px-8">
    <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        <div className="col-span-2 lg:col-span-1 text-gray-800">
            <h2 className="text-3xl font-bold mb-6">1. Análise de Contrato</h2>
            <p className="text-lg mb-6">O contrato de compra é a base de toda a transação imobiliária. Antes de assiná-lo, é essencial:</p>
            <ul className="list-disc list-inside text-lg">
                <li><strong>Verificar cláusulas que garantam a entrega do imóvel</strong>.</li>
                <li><strong>Confirmar penalidades em caso de descumprimento</strong>.</li>
                <li><strong>Solicitar a revisão por um advogado especializado</strong>.</li>
            </ul>
            <p className="mt-6 text-lg">Exemplo comum: Um comprador assinou um contrato sem cláusula de rescisão. Isso resultou em um processo longo quando o imóvel não foi entregue.</p>
        </div>
        <div className="flex justify-center col-span-1">
            <img src="/images/contract-analysis.jpg" alt="Pessoa analisando um contrato imobiliário" className="rounded-lg shadow-md max-w-full" />
        </div>
    </div>
</div>

<div className="section py-16 bg-gray-100 px-8">
    <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 items-center">
        <div className="order-2 lg:order-1 col-span-2 lg:col-span-1">
            <h2 className="text-3xl font-bold mb-6">2. Averbação de Escritura</h2>
            <p className="text-lg mb-6">A averbação da escritura é o processo que formaliza a transferência de propriedade no registro de imóveis. Para isso:</p>
            <ul className="list-disc list-inside text-lg">
                <li><strong>Certifique-se de que o vendedor é o proprietário registrado</strong>.</li>
                <li><strong>Verifique se o imóvel está livre de ônus e disputas judiciais</strong>.</li>
                <li><strong>Confirme que a escritura pública foi lavrada corretamente</strong>.</li>
            </ul>
            <p className="mt-6 text-lg">Exemplo: Um comprador descobriu, após a aquisição, que o imóvel possuía uma hipoteca não declarada.</p>
        </div>
        <div className="order-1 lg:order-2 flex justify-center col-span-1">
            <img src="/images/property-deed.jpg" alt="Registro de escritura de imóvel" className="rounded-lg shadow-md max-w-full" />
        </div>
    </div>
</div>

<div className="section py-16 px-8">
    <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        <div className="text-gray-800 col-span-2 lg:col-span-1">
            <h2 className="text-3xl font-bold mb-6">3. Checagem de Débitos</h2>
            <p className="text-lg mb-6">Antes de concluir a compra, é fundamental garantir que o imóvel esteja regularizado. Certifique-se de:</p>
            <ul className="list-disc list-inside text-lg">
                <li><strong>Consultar dívidas de IPTU e taxas de condomínio</strong>.</li>
                <li><strong>Verificar pendências em serviços como água e energia elétrica</strong>.</li>
                <li><strong>Solicitar uma certidão negativa de débitos municipais</strong>.</li>
            </ul>
            <p className="mt-6 text-lg">Exemplo: Um comprador assumiu um imóvel com débitos acumulados, resultando em custos inesperados.</p>
        </div>
        <div className="flex justify-center col-span-1">
            <img src="/images/debt-check.jpg" alt="Checando dívidas de imóvel" className="rounded-lg shadow-md max-w-full" />
        </div>
    </div>
</div>

<div className="cta-section py-20 bg-primary text-white text-center">
    <div className="max-w-3xl mx-auto">
        <h2 className="text-4xl font-bold mb-8">Proteja Seu Investimento Imobiliário</h2>
        <p className="text-lg mb-8">Compreender os aspectos jurídicos da compra de um imóvel é essencial para evitar problemas e garantir uma transação segura.</p>
        <a href="/contact" className="bg-white text-primary px-10 py-4 rounded-full shadow-md hover:bg-gray-200 transition-all text-lg font-semibold">
            FALE COM UM ADVOGADO ESPECIALIZADO
        </a>
    </div>
</div>


    `},
];

const BlogDetailPage = () => {
    const { postId } = useParams();
    const post = blogPosts.find((post) => post.id === parseInt(postId));

    if (!post) return <p>Postagem não encontrada.</p>;

    return (
        <div className="py-12 px-6">
            <h1 className="text-5xl font-extrabold text-primary text-center mb-6 border-b-4 border-primary pb-4">
                {post.title}
            </h1>
            <div className="flex justify-center items-center mb-6">
                <span className="text-sm text-gray-500">Publicado em:</span>
                <p className="text-sm text-gray-600 ml-2">{new Date(post.date).toLocaleDateString()}</p>
            </div>
            <div className="prose prose-lg max-w-none text-black mb-6" dangerouslySetInnerHTML={{ __html: post.content }}></div>
            <div className="text-center">
                <Link
                    to="/blog"
                    className="bg-primary text-secondary px-6 py-3 rounded-lg shadow-lg hover:bg-tertiary hover:text-primary transition-all"
                >
                    Voltar para o Blog
                </Link>
            </div>
        </div>
    );
};

export default BlogDetailPage;