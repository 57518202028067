import React from 'react';
import { Link } from 'react-router-dom';

const BlogPage = () => {
    const blogPosts = [
        { id: 1, title: "Artigo 1: Introdução à Advocacia", date: "2024-01-01", description: "Uma introdução sobre como a advocacia pode impactar a sociedade." },
        { id: 2, title: "Artigo 2: Direito Imobiliário", date: "2024-01-02", description: "Entenda os princípios básicos do Direito Imobiliário e sua aplicação." },
        { id: 3, title: "Entenda a Diferença Entre Contrato de Compra e Venda e Contrato de Locação", date: "2024-01-10", description: "Exploração detalhada das diferenças entre compra, venda e locação." },
        { id: 4, title: "O Que Considerar Antes de Comprar um Imóvel: Guia Jurídico Completo", date: "2024-01-10", description: "Exploração detalhada das diferenças entre compra, venda e locação." },
    ];

    return (
        <div className="py-12 px-6">
            {/* Cabeçalho */}
            <h1 className="text-5xl font-extrabold text-center text-primary mb-6 drop-shadow-lg border-b-4 border-primary pb-4">
                Blog Jurídico
            </h1>
            <p className="text-lg text-center text-gray-700 mb-12 max-w-3xl mx-auto">
                Explore artigos exclusivos sobre temas jurídicos e fique por dentro das novidades do mundo do direito.
            </p>

            {/* Grade de Artigos */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {blogPosts.map((post) => (
                    <div key={post.id} className="p-6 bg-secondary text-primary rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition-all">
                        <h2 className="text-2xl font-bold mb-3">{post.title}</h2>
                        <p className="text-sm text-gray-500 mb-4 flex items-center">
                            <svg className="w-4 h-4 mr-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 4h10M3 21h18M4 10h16m-1 6h-2m-4 0H8" />
                            </svg>
                            {new Date(post.date).toLocaleDateString()}
                        </p>
                        <p className="text-sm text-gray-700 mb-6">{post.description}</p>
                        <Link
                            to={`/blog/${post.id}`}
                            className="inline-block bg-primary text-secondary px-4 py-2 rounded-full shadow-md hover:bg-tertiary hover:text-primary transition-all"
                            aria-label={`Leia mais sobre ${post.title}`}
                        >
                            Leia mais
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogPage;