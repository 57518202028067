import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const services = [
    {
        id: 'imobiliario',
        name: 'Direito Imobiliário e Contratual',
        description: 'Garantimos segurança jurídica em suas transações imobiliárias e negociações contratuais.',
        details: [
            'Compra e venda de imóveis.',
            'Elaboração e revisão de contratos.',
            'Resolução de disputas imobiliárias e contratuais.',
        ],
    },
    {
        id: 'criminal',
        name: 'Direito Criminal e Execução Penal',
        description: 'Prestamos suporte jurídico especializado e defesa técnica em processos criminais.',
        details: [
            'Defesa técnica em processos criminais.',
            'Acompanhamento em execuções penais.',
            'Garantia de direitos em casos de acusação.',
        ],
    },
    {
        id: 'familia',
        name: 'Direito de Família e Sucessões',
        description: 'Auxiliamos em questões delicadas como divórcios, guarda de filhos e planejamento sucessório.',
        details: [
            'Divórcios e dissoluções de união estável.',
            'Guarda e regulamentação de visitas.',
            'Planejamento sucessório e inventários.',
        ],
    },
];

const qualities = [
    'Atendimento personalizado.',
    'Experiência comprovada.',
    'Foco em resultados.',
    'Ética e transparência.',
];

const ServicesPage = () => {
    const [currentQuality, setCurrentQuality] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentQuality((prev) => (prev + 1) % qualities.length);
        }, 2500); // Alterna a cada 4 segundos
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative overflow-hidden">
            {/* Background Animation */}
            <div className="absolute inset-0 bg-gradient-to-r from-tertiary to-primary animate-gradient-x opacity-20"></div>

            <div className="relative z-10 py-12 px-6">
                {/* Hero Section */}
                <section className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-primary mb-2">
                        Serviços Jurídicos – Araújo & Bezerra - Advocacia
                    </h1>
                    <p className="text-lg text-black max-w-3xl mx-auto">
                        Na Araújo & Bezerra - Advocacia, oferecemos uma ampla gama de serviços jurídicos especializados, com foco em excelência, ética e resultados práticos.
                    </p>
                </section>

                {/* Services Section */}
                <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service) => (
                        <div
                            key={service.id}
                            className="p-6 bg-tertiary text-secondary rounded-lg shadow-lg hover:shadow-2xl hover:scale-105 transition-transform"
                        >
                            <h2 className="text-xl text-white font-semibold mb-4">{service.name}</h2>
                            <p className="mb-4">{service.description}</p>
                            <ul className="list-disc pl-4 mb-4 space-y-2">
                                {service.details.map((detail, index) => (
                                    <li key={index} className="text-sm">{detail}</li>
                                ))}
                            </ul>
                            <Link
                                to={`/servicos/${service.id}`}
                                className="inline-block mt-4 bg-primary text-secondary px-4 py-2 rounded shadow hover:bg-secondary hover:text-primary transition"
                            >
                                Saiba Mais
                            </Link>
                        </div>
                    ))}
                </section>

                {/* Call-to-Action Section */}
                <section className="text-center mt-12">
                    <h2 className="text-2xl font-bold mb-4">Por Que Escolher a Araújo & Bezerra - Advocacia?</h2>
                    <p className="text-lg max-w-2xl mx-auto mb-6 transition-opacity duration-1000 ease-in-out">
                        {qualities[currentQuality]}
                    </p>
                    <a
                        href="https://api.whatsapp.com/send?phone=5551999999999&text=Olá, gostaria de saber mais sobre os serviços."
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-primary text-secondary px-6 py-3 rounded-full shadow hover:bg-tertiary hover:text-primary transition"
                    >
                        Fale Conosco no WhatsApp
                    </a>
                </section>
            </div>
        </div>
    );
};

export default ServicesPage;
