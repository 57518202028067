import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const serviceDetails = {
    imobiliario: {
        title: 'Direito Imobiliário e Contratual',
        description: `
      Garantimos segurança jurídica em todas as suas transações imobiliárias e negociações contratuais. Atuamos em:
      - Compra e venda de imóveis.
      - Elaboração e revisão de contratos.
      - Resolução de disputas imobiliárias e contratuais.

      Seja qual for a complexidade do seu caso, nosso objetivo é proteger seus interesses e assegurar que cada transação seja realizada com total transparência e legalidade.
    `,
        faq: [
            {
                question: 'O que é necessário para comprar um imóvel de forma segura?',
                answer: 'É essencial realizar uma análise jurídica completa, verificando a documentação do imóvel e do vendedor para evitar problemas futuros.',
            },
            {
                question: 'Qual a importância de um contrato de locação?',
                answer: 'Um contrato de locação bem elaborado protege tanto o locador quanto o locatário, evitando conflitos legais.',
            },
            {
                question: 'Posso desfazer a compra de um imóvel após assinar o contrato?',
                answer: 'Sim, mas depende das cláusulas contratuais e da legislação vigente. É importante buscar assessoria jurídica.',
            },
        ],
    },
    criminal: {
        title: 'Direito Criminal e Execução Penal',
        description: `
      Prestamos suporte jurídico especializado em:
      - Defesa técnica em processos criminais.
      - Acompanhamento em execuções penais.
      - Garantia de direitos em casos de acusação.

      Trabalhamos de forma estratégica para oferecer um atendimento técnico e eficiente, priorizando sempre a preservação dos seus direitos e garantias legais.
    `,
        faq: [
            {
                question: 'O que fazer ao receber uma intimação criminal?',
                answer: 'É fundamental buscar um advogado especializado para orientar sobre os próximos passos.',
            },
            {
                question: 'Quais são os direitos de um réu em um processo criminal?',
                answer: 'Direito à defesa, presunção de inocência e acesso ao processo são garantias fundamentais.',
            },
            {
                question: 'O que é execução penal?',
                answer: 'É a fase em que se cumpre a pena definida pela sentença, podendo incluir regime fechado, semiaberto ou aberto.',
            },
        ],
    },
    familia: {
        title: 'Direito de Família e Sucessões',
        description: `
      Auxiliamos em questões familiares e sucessórias com sensibilidade e profissionalismo. Nossos serviços incluem:
      - Divórcios e dissoluções de união estável.
      - Guarda e regulamentação de visitas.
      - Planejamento sucessório e inventários.

      Nosso compromisso é resolver conflitos com eficácia e minimizar os impactos emocionais.
    `,
        faq: [
            {
                question: 'O que é necessário para iniciar um processo de divórcio?',
                answer: 'Documentos pessoais, certidão de casamento e, em alguns casos, acordo sobre partilha de bens e guarda de filhos.',
            },
            {
                question: 'Como funciona a guarda compartilhada?',
                answer: 'É a divisão equilibrada das responsabilidades parentais, garantindo o bem-estar da criança.',
            },
            {
                question: 'O que é pensão alimentícia e como é calculada?',
                answer: 'É um valor pago para atender às necessidades básicas de um dependente, calculado com base na renda de quem paga.',
            },
        ],
    },
};

const ServiceDetailPage = () => {
    const { serviceId } = useParams();
    const navigate = useNavigate();
    const service = serviceDetails[serviceId];

    if (!service) {
        return <p>Serviço não encontrado.</p>;
    }

    const handleWhatsAppClick = () => {
        const phone = '5511999999999'; // Substitua pelo número do WhatsApp do cliente
        const text = `Olá, gostaria de saber mais sobre o serviço de ${service.title}.`;
        window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(text)}`, '_blank');
    };

    return (
        <div className="py-12 px-6">
            <h1 className="text-4xl font-bold text-black mb-6">{service.title}</h1>
            <p className="text-lg text-black mb-6 whitespace-pre-line">{service.description}</p>

            {/* FAQ Section */}
            <h2 className="text-2xl font-bold text-black mb-4">Perguntas Frequentes</h2>
            <div className="space-y-4">
                {service.faq.map((item, index) => (
                    <div
                        key={index}
                        className="p-4 bg-secondary rounded shadow-lg hover:shadow-xl transition"
                    >
                        <h3 className="font-semibold text-black">{item.question}</h3>
                        <p className="text-black mt-2">{item.answer}</p>
                    </div>
                ))}
            </div>

            {/* Buttons Section */}
            <div className="mt-8 flex gap-4">
                <button
                    onClick={handleWhatsAppClick}
                    className="bg-tertiary text-secondary px-6 py-3 rounded-lg shadow-md hover:bg-primary hover:text-white transition"
                >
                    Fale conosco no WhatsAPP
                </button>
                <button
                    onClick={() => navigate('/servicos')}
                    className="bg-primary text-secondary px-6 py-3 rounded-lg shadow-md hover:bg-tertiary hover:text-white transition"
                >
                    Voltar
                </button>
            </div>
        </div>
    );
};

export default ServiceDetailPage;
