import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="bg-tertiary text-secondary shadow-md fixed top-0 w-full z-50">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                {/* Ícone no Header */}
                <div className="flex items-center">
                    <img
                        src="/images/logo-icon.jpg" // Atualize com o caminho correto do ícone
                        alt="Logo Icon"
                        className="h-12 w-12 mr-2 object-contain"
                    />
                    <h1 className="text-xl font-bold text-white">
                        <Link to="/">Araújo & Bezerra</Link>
                    </h1>
                </div>

                {/* Botão Mobile */}
                <button
                    className="block md:hidden text-secondary"
                    onClick={toggleMenu}
                >
                    ☰
                </button>

                {/* Navegação */}
                <nav
                    className={`${isMenuOpen ? 'block' : 'hidden'
                        } md:block absolute md:relative top-16 md:top-0 left-0 md:left-auto bg-tertiary md:bg-transparent w-full md:w-auto shadow-md md:shadow-none`}
                >
                    <ul className="flex flex-col md:flex-row md:space-x-6 text-center md:text-left">
                        <li>
                            <Link
                                to="/sobre"
                                className="block py-2 px-4 hover:text-primary transition"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Sobre
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/servicos"
                                className="block py-2 px-4 hover:text-primary transition"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Serviços
                            </Link>
                        </li>
                        {/* 
                        <li>
                            <Link
                                to="/cases-de-sucesso"
                                className="block py-2 px-4 hover:text-primary transition"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Cases de Sucesso
                            </Link>
                        </li>
                        */}
                        <li>
                            <Link
                                to="/seja-advogado-dativo"
                                className="block py-2 px-4 hover:text-primary transition"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Seja um Advogado Dativo
                            </Link>
                        </li>
                        {/* 
                        <li>
                            <Link
                                to="/blog"
                                className="block py-2 px-4 hover:text-primary transition"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Blog
                            </Link>
                        </li>
                        */}
                        <li>
                            <a
                                href="https://api.whatsapp.com/send?phone=5511999999999"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block py-2 px-4 hover:text-primary transition"
                            >
                                Contato via WhatsApp
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
