import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const services = [
    { id: 'imobiliario', name: 'Direito Imobiliário e Contratual', description: 'Gerenciamento de contratos e propriedades.' },
    { id: 'criminal', name: 'Direito Criminal e Execução Penal', description: 'Defesa e acompanhamento de processos criminais.' },
    { id: 'familia', name: 'Direito de Família e Sucessões', description: 'Auxílio em questões familiares e sucessões.' },
];

const messages = [
    'Excelência em Direito Imobiliário, Criminal e Familiar.',
    'Estamos prontos para ajudá-lo em cada etapa do seu caso.',
    'Confiança e Transparência em Soluções Jurídicas.',
    'Compromisso com seus Direitos e Garantias Legais.',
];

const HomePage = () => {
    const [currentMessage, setCurrentMessage] = useState(0);
    const videoRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessage((prev) => (prev + 1) % messages.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play().catch((err) => {
                console.warn('Play attempt failed. Interaction might be required by the browser.');
            });
        }
    };

    const handleMouseEnter = () => {
        if (window.innerWidth >= 768) {
            handlePlay();
        }
    };

    const handleVideoEnd = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    return (
        <div>
            {/* Hero Section */}
            <section
                className="relative w-screen h-screen"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => videoRef.current.pause()}
                aria-labelledby="hero-title"
            >
                <video
                    ref={videoRef}
                    src="/videos/hero-video.mp4"
                    className="absolute inset-0 w-full h-full object-cover"
                    onEnded={handleVideoEnd}
                    poster="/images/hero-fallback.jpg"
                    muted
                    aria-label="Introdução visual do escritório com paisagens e cenas corporativas."
                />
                <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50">
                    <h1
                        id="hero-title"
                        className="text-7xl font-extrabold text-center text-white drop-shadow-lg tracking-wide mt-16 animate-fade-in-down"

                    >
                        Araújo & Bezerra
                    </h1>
                    <p
                        className="mt-8 text-2xl text-center text-white max-w-3xl transition-opacity duration-1000 ease-in-out animate-fade-in"
                    >
                        {messages[currentMessage]}
                    </p>
                    <a
                        href="#services"
                        className="mt-8 bg-primary text-white py-3 px-6 rounded-full shadow-lg hover:scale-110 hover:bg-tertiary focus:scale-95 focus:ring-4 focus:ring-tertiary transition-all duration-300"
                    >
                        Conheça Nossos Serviços
                    </a>

                </div>
            </section>

            {/* Location Section */}
            <section className="py-8 bg-gray-100">
                <h2 className="text-3xl font-bold text-center text-gray-900 mb-4">Nossa Localização</h2>
                <div className="container mx-auto px-4">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248.3902382006342!2d-42.80441724043793!3d-5.064185785647665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78e390074cdda6f%3A0xdb70afda52f28a3f!2sAra%C3%BAjo%20%26%20Bezerra!5e0!3m2!1spt-BR!2sbr!4v1733835287776!5m2!1spt-BR!2sbr"
                        width="100%"
                        height="400"
                        allowFullScreen=""
                        loading="lazy"
                        title="Localização"
                        className="rounded-lg shadow-md animate-fade-in"
                    ></iframe>
                </div>
            </section>

            {/* Services Section */}
            <section id="services" className="container mx-auto py-16 px-6">
                <h2 className="text-4xl font-bold text-center text-gray-900 mb-12">Nossos Serviços</h2>
                <p className="text-center text-lg text-gray-700 mb-8 max-w-4xl mx-auto">
                    Oferecemos um portfólio completo de serviços jurídicos personalizados para atender às suas necessidades.
                    Nossa equipe está preparada para fornecer soluções práticas e eficazes para casos complexos.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service) => (
                        <Link
                            key={service.id}
                            to={`/services/${service.id}`}
                            className="group block bg-gray-100 text-gray-800 p-8 rounded-xl shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-300 animate-zoom-in"
                        >
                            <h3 className="text-2xl font-semibold group-hover:text-gray-900 transition-colors duration-300">
                                {service.name}
                            </h3>
                            <p className="mt-4 text-sm text-gray-700">
                                {service.description}
                            </p>
                        </Link>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default HomePage;
