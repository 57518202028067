import { motion } from "framer-motion";

const cases = [
    { id: 1, title: "Caso Civil", description: "Descrição breve do caso civil.", details: "Mais informações sobre o caso civil..." },
    { id: 2, title: "Caso Trabalhista", description: "Descrição breve do caso trabalhista.", details: "Mais informações sobre o caso trabalhista..." },
];

const SuccessCases = () => {
    return (
        <section id="cases" className="bg-gray-50 py-12">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                className="max-w-7xl mx-auto px-4"
            >
                <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Cases de Sucesso</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {cases.map((caseItem) => (
                        <motion.div
                            key={caseItem.id}
                            className="bg-gray-100 p-6 rounded-lg shadow hover:shadow-md transition"
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <h3 className="text-xl font-semibold mb-2 text-gray-700">{caseItem.title}</h3>
                            <p className="text-gray-600">{caseItem.description}</p>
                        </motion.div>
                    ))}
                </div>
            </motion.div>
        </section>
    );
};

export default SuccessCases;
